import {Injectable} from '@angular/core';
import {AbstractHttpService} from './abstract-http-service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {OfferSetSharingRuleModel} from '../model/offer-set-sharing-rule.model';
import {OfferSetSharingRuleFilterDefinitionModel} from '../model/offer-set-sharing-rule-filter-definition.model';
import {OfferSetSharingRuleRequestModel} from '../model/offer-set-sharing-rule-request.model';
import {SharingRuleFilterModel} from '../model/sharing-rule-filter.model';

@Injectable({
  providedIn: 'root'
})
export class SharedOfferSetRuleService extends AbstractHttpService {

  options = {
    headers: this.acceptJsonHeaders,
    withCredentials: true
  };

  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  getSharedOfferSetRules(): Observable<OfferSetSharingRuleModel[]> {
    return this.httpClient.get<OfferSetSharingRuleModel[]>(
      `//${environment.autoDomain}:${environment.autoPort}/api/offer-set-sharing-rules`,
      this.options
    ).pipe(
      catchError(this.handleError)
    );
  }

  getOfferSetSharingRuleFilterDefinition() {
    return this.httpClient.get<OfferSetSharingRuleFilterDefinitionModel[]>(
      `//${environment.autoDomain}:${environment.autoPort}/api/offer-set-sharing-rule-filter-definition`,
      this.options
    ).pipe(
      catchError(this.handleError)
    );
  }

  getSharedOfferSetRuleFilter(sharingRuleFilter: SharingRuleFilterModel) {
    return this.httpClient.post<any[]>(
      `//${environment.autoDomain}:${environment.autoPort}/api/shared-offer-sets-filter`,
      sharingRuleFilter,
      this.options
    ).pipe(
      catchError(this.handleError)
    );
  }

  applySharedOfferSetRuleFilters(sharedOfferSetsFilterIds: any[]): Observable<any> {
    return this.httpClient.post<any[]>(
      `//${environment.autoDomain}:${environment.autoPort}/api/shared-offer-sets-filter-application`,
      {
        sharedOfferSetsFilterIds
      },
      this.options
    ).pipe(
      catchError(this.handleError)
    );
  }

  updateSharedOfferSetRule(offerSetSharingRuleRequestModel: OfferSetSharingRuleRequestModel): Observable<any> {
    return this.httpClient.put<OfferSetSharingRuleRequestModel>(
      `//${environment.autoDomain}:${environment.autoPort}/api/offer-set-sharing-rule`,
      offerSetSharingRuleRequestModel,
      this.options
    ).pipe(
      catchError(this.handleError)
    );
  }

  public deleteSharedOfferSetRule(offerSetSharingRuleId: number) {
    return this.httpClient.delete<OfferSetSharingRuleRequestModel>(
      `//${environment.autoDomain}:${environment.autoPort}/api/offer-set-sharing-rule/` + offerSetSharingRuleId,
      this.options
    ).pipe(
      catchError(this.handleError)
    );
  }
}

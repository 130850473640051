<form [formGroup]="ruleForm">
  <div class="sharing-rule-input">
    <label class="input-spacing">Rule Name:</label>
      <input
        type="text"
        pInputText
        class="input-field-width"
        formControlName="ruleName"
        id="rule-name">
  </div>
  <div class="sharing-rule-input">
    <label class="input-spacing">Source Account:</label>
      <p-dropdown
        formControlName="sourceAccount"
        placeholder="Select Source Account"
        [options]="sourceAccountOptions"
        [filter]="true"
        class="input-field-width p-dropdown"
        id="source-account">
      </p-dropdown>
  </div>
  <app-shared-offer-set-rules-filter
    [ruleForm]="ruleForm"
    controlName="offerSetFilter"
    label="Offer Set Filter"
    [propertyValueFilters]="offerFilters">
  </app-shared-offer-set-rules-filter>
  <app-shared-offer-set-rules-filter
    [ruleForm]="ruleForm"
    controlName="accountFilter"
    label="Account Filter"
    [propertyValueFilters]="accountFilters">
  </app-shared-offer-set-rules-filter>
<!--  TODO: add in the excluded accounts field once filter process is in place-->
  <!--  <div>-->
  <!--    <label>Excluded Accounts:</label>-->
  <!--    <p-dropdown placeholder="Excluded Account"></p-dropdown>-->
  <!--  </div>-->
  <div class="confirm-button-field">
    <div>
      <button pButton type="button" class="cancel-button-field" label="Cancel" (click)="closeModal()"></button>
      <button
        pButton
        type="button"
        [disabled]="isInvalidForm() || ruleProcessing"
        class="p-button-success"
        label="Confirm"
        (click)="saveRule()">
      </button>
      <p-progressSpinner *ngIf="ruleProcessing" class="p-button-success" [style]="{height: '2.5rem'}" strokeWidth="8"></p-progressSpinner>
    </div>
  </div>
  <div *ngIf="isInvalidForm()" class="input-validation-wrapper">
    <small class="p-d-block p-error block">The Rule Name and Source Account must be filled out in order to save.</small>
  </div>
</form>

export class SharingRuleFilterModel {

  sourceAccountId: number;
  offerSetSharingRuleFilters: any[];

    constructor() {
        this.sourceAccountId = null;
        this.offerSetSharingRuleFilters = [];
    }
}
